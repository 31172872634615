import { gql } from '@apollo/client'

export const RENEW_BATCHES_MUTATION = gql`
  mutation renewBatches($input: RenewBatchesDtoInput!) {
    renewBatches(input: $input) {
      id
      isSentToXero
      isSuccess
    }
  }
`

export const RENEW_ALL_BATCHES_MUTATION = gql`
  mutation renewAllBatches {
    renewAllBatches {
      id
      isSentToXero
      isSuccess
    }
  }
`
