import { LoadingButton } from '@mui/lab'
import { Grid, Typography } from '@mui/material'
import { GridSortItem } from '@mui/x-data-grid'

import {
  BookingInvoiceBatchLineFilterInput,
  useGetBookingInvoiceBatchQuery,
  useRenewBatchesMutation,
} from 'generated/graphql'
import { GET_BOOKING_INVOICE_BATCH_LINES } from 'graphql/queries/BookingInvoiceBatchedQueries'
import { ExtractValidationErrorMessages } from 'helpers'
import { useGetInvoiceBatchLinesColummns } from 'hooks/accountsPayable/useGetInvoiceBatchLinesColumns'

import ExpandableGrid from '../common/ExpandableDataGrid/ExpandanbleGrid'
import AccountPayableCompactedGrid from './AccountPayableCompactedGrid'

const descendingOrderById: GridSortItem = {
  field: 'id',
  sort: 'desc',
}

interface IProps {
  batchId: number
}

function BatchDetailsGrid({ batchId }: IProps) {
  const columns = useGetInvoiceBatchLinesColummns()

  const filterByBatchId: BookingInvoiceBatchLineFilterInput = {
    batchId: { eq: Number(batchId) },
  }

  const { data: bookingInvoiceBatchData } = useGetBookingInvoiceBatchQuery({
    variables: { id: Number(batchId) },
  })

  const [renewBatches, { loading }] = useRenewBatchesMutation()

  const handleRenewStatus = () => {
    renewBatches({
      variables: {
        input: {
          ids: [Number(batchId)],
        },
      },
    })
  }

  const bookingInvoiceBatch = bookingInvoiceBatchData?.bookingInvoiceBatch
  const isSentToXero = bookingInvoiceBatch?.isSentToXero
  const isSuccess = bookingInvoiceBatch?.isSuccess
  const errorMessage = bookingInvoiceBatch?.errorMessage

  const formattedErrorMessage = errorMessage && ExtractValidationErrorMessages(errorMessage)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6' component='h6' marginBottom={2}>
            Invoice Batches Details - Batch ID {batchId}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='h6' component='h6' marginBottom={2}>
            Sent to Xero? - {isSentToXero ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            variant='outlined'
            disabled={isSuccess || !isSentToXero}
            loading={loading}
            onClick={handleRenewStatus}>
            Renew status
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' component='h6' marginBottom={2}>
            Is successful? - {isSuccess ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {errorMessage && (
            <Typography variant='h6' component='h6' marginBottom={2}>
              Error Message - {formattedErrorMessage || errorMessage}
            </Typography>
          )}
        </Grid>
      </Grid>
      <ExpandableGrid
        entityName='bookingInvoiceBatchLines'
        query={GET_BOOKING_INVOICE_BATCH_LINES}
        filter={filterByBatchId}
        columns={columns}
        expandedItem={<AccountPayableCompactedGrid />}
        defaultOrder={descendingOrderById}
      />
    </>
  )
}

export default BatchDetailsGrid
