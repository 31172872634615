import { gql } from '@apollo/client'

export * from './BookingCombinedMutations'
export * from './BookingMutations'
export * from './CongestionZoneMutations'
export * from './CourierExchangeMutations'
export * from './CustomerMutations'
export * from './DriverMutations'
export * from './EmailMutations'
export * from './LocalStateMutations'
export * from './OtherChargeMutations'
export * from './ScheduledBookingMutations'
export * from './TariffMutations'
export * from './VehicleMutations'
export * from './UserMutations'
export * from './TaskMutations'
export * from './CommentaryMutations'
export * from './ScheduledExchangeRateMutations'
export * from './ReportsMutations'
export * from './ExchangeRateMutations'
export * from './ActivityMutations'
export * from './NotificationMutations'
export * from './TaskSettingMutations'
export * from './BannerMutations'
export * from './BookingAttachmentMutations'
export * from './BookingDriverMutations'
export * from './XeroCodeMutations'
export * from './ReasonCodeMutations'
export * from './PaymentMutatuons'
export * from './OpenQueryMutations'
export * from './AccountPayableMutations'
export * from './BatchMutations'

export const CREATE_INVOICE_TASK_MUTATION = gql`
  mutation createInvoiceTask {
    createInvoiceTask
  }
`

export const PROCESS_INVOICE_TASK_MUTATION = gql`
  mutation processInvoiceTask {
    processTasks
  }
`

export const VERIFY_ADDRESS_DELIVERY = gql`
  mutation verifyAddressDelivery {
    verifyAddressDelivery
  }
`
