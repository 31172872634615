import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Typography } from '@mui/material'

import BatchDetailsGrid from 'components/bookingInvoiceBatchDetails/BatchDetailsGrid'
import { FplDataGrid } from 'components/common'
import {
  BookingInvoiceBatchFilterInput,
  InvoiceType,
  useRenewAllBatchesMutation,
  useRenewBatchesMutation,
} from 'generated/graphql'
import { GET_BOOKING_INVOICE_BATCHES } from 'graphql/queries'
import { useGetInvoiceBatchesColummns } from 'hooks/accountsPayable/useGetInvoiceBatchesColumns'
import { usePermission } from 'providers'

const invoiceBatchFilter: BookingInvoiceBatchFilterInput = {
  invoiceType: { eq: InvoiceType.Purchase },
}

const InvoiceBatches = () => {
  const [isAllowedToRenewBatches] = usePermission('RenewBatches')
  const [selectedBatchRow, setSelectedBatchRow] = useState<null | number>(null)
  const [selectedBatchIds, setSelectedBatchIds] = useState<Array<string>>([])

  const [renewBatches, { loading: renewingBatches }] = useRenewBatchesMutation()
  const [renewAllBatches, { loading: renewingAllBatches }] = useRenewAllBatchesMutation()

  const handleRowClickAllGrid = (row) => {
    setSelectedBatchRow(row.id)
  }

  const handleRenewBatchesClick = () => {
    renewBatches({
      variables: {
        input: {
          ids: selectedBatchIds.map((i) => Number(i)),
        },
      },
    })
  }

  const handleRenewAllBatchesClick = () => renewAllBatches()

  const columns = useGetInvoiceBatchesColummns()

  const leftSide = (
    <>
      <Grid item>
        <LoadingButton
          variant='outlined'
          onClick={handleRenewAllBatchesClick}
          loading={renewingAllBatches}>
          Renew All
        </LoadingButton>
      </Grid>

      <Grid item>
        <LoadingButton
          variant='outlined'
          disabled={selectedBatchIds.length === 0}
          onClick={handleRenewBatchesClick}
          loading={renewingBatches}>
          Renew
        </LoadingButton>
      </Grid>
    </>
  )

  return (
    <Box>
      {!selectedBatchRow && (
        <FplDataGrid
          query={GET_BOOKING_INVOICE_BATCHES}
          refetchWithDelay
          filter={invoiceBatchFilter}
          checkboxSelection
          entityName='bookingInvoiceBatches'
          toolbar={{
            caption: 'Invoice Batches',
            leftSide: isAllowedToRenewBatches ? leftSide : undefined,
          }}
          columns={columns}
          onRowClick={(e) => handleRowClickAllGrid(e)}
          onSelectionModelChange={(e) => setSelectedBatchIds(e as string[])}
        />
      )}
      {selectedBatchRow && (
        <>
          <Button sx={{ marginRight: '15px' }} onClick={() => setSelectedBatchRow(null)}>
            <ArrowBackIcon color='primary'></ArrowBackIcon>
            <Typography color={'primary'}>Back</Typography>
          </Button>

          <BatchDetailsGrid batchId={selectedBatchRow} />
        </>
      )}
    </Box>
  )
}

export default InvoiceBatches
